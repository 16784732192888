var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"contentForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"parent_id","rules":"required","name":"The Parent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"parent_id","size":"large","filterable":"","clearable":"","placeholder":"Parent","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Parent',"list":_vm.pageList,"listItemLabel":'label',"listItemValue":'id'},model:{value:(_vm.formData.parent_id),callback:function ($$v) {_vm.$set(_vm.formData, "parent_id", $$v)},expression:"formData.parent_id"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"page_name","rules":"required","name":"The Page Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Page Name","name":"page_name","fou":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"page_heading","rules":"required","name":"The Page Heading"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Page Heading","name":"page_heading","fou":""},model:{value:(_vm.formData.page_heading),callback:function ($$v) {_vm.$set(_vm.formData, "page_heading", $$v)},expression:"formData.page_heading"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"page_url","rules":"required","name":"The Page Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Page Url","name":"page_url","fou":""},model:{value:(_vm.formData.page_url),callback:function ($$v) {_vm.$set(_vm.formData, "page_url", $$v)},expression:"formData.page_url"}})]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Banner Image")]),_c('image-uploader',{attrs:{"preview-width":'200px',"preview-height":'200px'},model:{value:(_vm.formData.banner_image),callback:function ($$v) {_vm.$set(_vm.formData, "banner_image", $$v)},expression:"formData.banner_image"}})],1)],1),_c('div',{staticClass:"col-md-12 com-sm-12 mb-2 mt-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Page Content")]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":{
                  height: 400,
                  paste_data_images: true,
                  plugins: [
                  "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                   "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media nonbreaking save table contextmenu directionality",
                  "emoticons template paste textcolor colorpicker textpattern"
                  ],
                  toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                  toolbar2: "print preview media | forecolor backcolor emoticons",
                  image_advtab: true,
                }},model:{value:(_vm.formData.page_content),callback:function ($$v) {_vm.$set(_vm.formData, "page_content", $$v)},expression:"formData.page_content"}})],1)]),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('fg-input',{attrs:{"type":"text","label":"Meta Title","name":"meta_title"},model:{value:(_vm.formData.meta_title),callback:function ($$v) {_vm.$set(_vm.formData, "meta_title", $$v)},expression:"formData.meta_title"}}),_c('fg-text',{attrs:{"type":"text","label":"Meta Description","name":"meta_description"},model:{value:(_vm.formData.meta_description),callback:function ($$v) {_vm.$set(_vm.formData, "meta_description", $$v)},expression:"formData.meta_description"}}),_c('fg-text',{attrs:{"type":"text","label":"Meta Keywords","name":"meta_keywords"},model:{value:(_vm.formData.meta_keywords),callback:function ($$v) {_vm.$set(_vm.formData, "meta_keywords", $$v)},expression:"formData.meta_keywords"}}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/pages/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }